import React from 'react';

import { CardContent } from '@/design-system/atoms/Cards/CardContent';
import UIContentCarousel from '@/design-system/molecules/ContentCarousel/ContentCarousel';
import { generateStyleModifiers } from '@/utils/generateStyleModifiers';

const ContentCarousel = (props) => {
    const { appliedCssClassNames, title, body, items, cta } = props;
    const modifiers = generateStyleModifiers(appliedCssClassNames);
    const theme = modifiers.theme;

    const normalizeItems = React.useMemo(() => {
        return Array.isArray(items)
            ? items.map((i, index) => (
                  <UIContentCarousel.Card key={index} fullHeight>
                      <CardContent
                          title={i.title}
                          body={i.body}
                          cta={{
                              href: i.href,
                              csr: i.csr,
                          }}
                          analyticsActionLabel={i.title}
                          analyticsComponentTitle={i.eyebrow}
                      />
                  </UIContentCarousel.Card>
              ))
            : [];
    }, [items]);

    return (
        <UIContentCarousel
            theme={theme}
            title={title}
            body={body}
            items={normalizeItems}
            cta={cta}
        />
    );
};

export default ContentCarousel;
